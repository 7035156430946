<template>
  <div>
    <el-divider></el-divider>
    <el-tabs v-model="activeName" v-loading="loading" :element-loading-text="$t('lang.加载中')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" @tab-click="handleTabClick">
      <el-tab-pane :label="$t('lang.温差报警')" name="first">
        <ve-table :columns="columns1" borderY :table-data="tableData1" row-key-field-name="id"></ve-table>
        <ve-pagination :total="searchObj1.total" :pageIndex="searchObj1.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="getList1"></ve-pagination>
      </el-tab-pane>
      <el-tab-pane :label="$t('lang.上下限报警')" name="second">
        <ve-table :columns="columns2" borderY :table-data="tableData2" row-key-field-name="id"></ve-table>
        <ve-pagination :total="searchObj2.total" :pageIndex="searchObj2.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="getList2"></ve-pagination>
      </el-tab-pane>
      <el-tab-pane :label="$t('lang.每天降温报警')" name="third">
        <ve-table :columns="columns3" borderY :table-data="tableData3" row-key-field-name="id"></ve-table>
        <ve-pagination :total="searchObj3.total" :pageIndex="searchObj3.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="getList3"></ve-pagination>
      </el-tab-pane>
      <el-tab-pane :label="$t('lang.每四小时降温报警')" name="fourth">
        <ve-table :columns="columns4" borderY :table-data="tableData4" row-key-field-name="id"></ve-table>
        <ve-pagination :total="searchObj4.total" :pageIndex="searchObj4.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="getList4"></ve-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Vue from "vue";

// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";

import { Divider } from "vant";
Vue.use(Divider);

Vue.use(VueEasytable);
export default {
  data() {
    let that = this;
    return {
      loading: false,
      deviceType: "",
      activeName: "first",
      searchObj1: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      columns1: [
        { field: "name", key: "name", title: that.$t("lang.温差名称") },
        {
          field: "chaZhi",
          key: "chaZhi",
          title: that.$t("lang.设置差值") + "(℃)",
          align: "right",
        },
        {
          field: "tongDao1",
          key: "tongDao1",
          title: that.$t("lang.通道1"),
          renderBodyCell: ({ row }) => {
            return (
              row.tongDao1SerialNumber + "(" + row.tongDao1Passageway + ")"
            );
          },
        },
        {
          field: "tongDao1DataTime",
          key: "tongDao1DataTime",
          title: that.$t("lang.通道1时间"),
        },
        {
          field: "tongDao1Temperature",
          key: "tongDao1Temperature",
          title: that.$t("lang.通道1温度") + "(℃)",
          align: "right",
        },
        {
          field: "tongDao2",
          key: "tongDao2",
          title: that.$t("lang.通道2"),
          renderBodyCell: ({ row }) => {
            return (
              row.tongDao2SerialNumber + "(" + row.tongDao2Passageway + ")"
            );
          },
        },
        {
          field: "tongDao2DataTime",
          key: "tongDao2DataTime",
          title: that.$t("lang.通道2时间"),
        },
        {
          field: "tongDao2Temperature",
          key: "tongDao2Temperature",
          title: that.$t("lang.通道2温度") + "(℃)",
          align: "right",
        },
      ],
      tableData1: [],
      searchObj2: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      columns2: [
        { field: "name", key: "name", title: that.$t("lang.上下限名称") },
        {
          field: "tongDao",
          key: "tongDao",
          title: that.$t("lang.通道"),
          renderBodyCell: ({ row }) => {
            return row.tongDaoSerialNumber + "(" + row.tongDaoPassageway + ")";
          },
        },
        {
          field: "tongDaoDataTime",
          key: "tongDaoDataTime",
          title: that.$t("lang.通道时间"),
        },
        {
          field: "tongDaoTemperature",
          key: "tongDaoTemperature",
          title: that.$t("lang.通道温度") + "(℃)",
          align: "right",
        },
        {
          field: "shangXian",
          key: "shangXian",
          title: that.$t("lang.上限值") + "(℃)",
          align: "right",
        },
        {
          field: "xiaXian",
          key: "xiaXian",
          title: that.$t("lang.下限值") + "(℃)",
          align: "right",
        },
      ],
      tableData2: [],
      searchObj3: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      columns3: [
        {
          field: "serialNumber",
          key: "serialNumber",
          title: that.$t("lang.序列号"),
        },
        { field: "tongDao", key: "tongDao", title: that.$t("lang.通道") },
        { field: "prev", key: "prev", title: that.$t("lang.时间间隔") },
        {
          field: "prevTemperature",
          key: "prevTemperature",
          title: that.$t("lang.平均温度") + "(℃)",
          align: "right",
        },
        { field: "next", key: "next", title: that.$t("lang.时间间隔") },
        {
          field: "nextTemperature",
          key: "nextTemperature",
          title: that.$t("lang.平均温度") + "(℃)",
          align: "right",
        },
        {
          field: "meiTianJiangWen",
          key: "meiTianJiangWen",
          title: that.$t("lang.设置差值") + "(℃)",
          align: "right",
        },
      ],
      tableData3: [],
      searchObj4: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      columns4: [
        {
          field: "serialNumber",
          key: "serialNumber",
          title: that.$t("lang.序列号"),
        },
        { field: "tongDao", key: "tongDao", title: that.$t("lang.通道") },
        { field: "prev", key: "prev", title: that.$t("lang.时间间隔") },
        {
          field: "prevTemperature",
          key: "prevTemperature",
          title: that.$t("lang.平均温度") + "(℃)",
          align: "right",
        },
        { field: "next", key: "next", title: that.$t("lang.时间间隔") },
        {
          field: "nextTemperature",
          key: "nextTemperature",
          title: that.$t("lang.平均温度") + "(℃)",
          align: "right",
        },
        {
          field: "meiSiXiaoShiJiangWen",
          key: "meiTianJiangWen",
          title: that.$t("lang.设置差值") + "(℃)",
          align: "right",
        },
      ],
      tableData4: [],
      tableData4check: [],
    };
  },
  methods: {
    handleTabClick() {
      let that = this;
      if (that.activeName == "first") {
        that.getList1();
      } else if (that.activeName == "second") {
        that.getList2();
      } else if (that.activeName == "third") {
        that.getList3();
      } else if (that.activeName == "fourth") {
        that.getList4();
      }
    },
    getList1(v) {
      let that = this;
      that.loading = true;
      if (v) {
        that.searchObj1.pageIndex = v;
      }
      that.axios
        .post("WXCW03_BJ/GetWCBJList", that.searchObj1)
        .then((response) => {
          that.tableData1 = response.data.data.data;
          that.searchObj1.total = response.data.data.total;
          that.loading = false;
        });
    },
    getList2(v) {
      let that = this;
      that.loading = true;
      if (v) {
        that.searchObj2.pageIndex = v;
      }
      that.axios
        .post("WXCW03_BJ/GetSXXBJList", that.searchObj2)
        .then((response) => {
          that.tableData2 = response.data.data.data;
          that.searchObj2.total = response.data.data.total;
          that.loading = false;
        });
    },
    getList3(v) {
      let that = this;
      that.loading = true;
      if (v) {
        that.searchObj3.pageIndex = v;
      }
      that.axios
        .post("WXCW03_BJ/GetMeiTianBJList", that.searchObj3)
        .then((response) => {
          that.tableData3 = response.data.data.data;
          that.searchObj3.total = response.data.data.total;
          that.loading = false;
        });
    },
    getList4(v) {
      let that = this;
      that.loading = true;
      if (v) {
        that.searchObj4.pageIndex = v;
      }
      that.axios
        .post("WXCW03_BJ/GetMeiSiXiaoShiBJList", that.searchObj4)
        .then((response) => {
          that.tableData4 = response.data.data.data;
          that.searchObj4.total = response.data.data.total;
          that.loading = false;
        });
    },
  },
  mounted() {
    let that = this;
    that.getList1();
  },
};
</script>

<style scoped>
.bgRed-WXCW03BJGL {
  background-color: #e6a23c !important;
}
</style>